import React, { useEffect, useState } from 'react'
import './viewSingleBatch.css';

import { ModalComponent, TableComponent } from '../../../commonComponents';

import { useDispatch,useSelector } from 'react-redux';
import { getInstructorReScheduleViewClassDetailDataView } from '../../../redux/instructorDashboard/action';
import { useNavigate, useParams } from 'react-router-dom';
import { retrieveBatchRelatedStudents } from '../../../redux/instructorBatches/action';
import { carouselLeft } from '../../../images';
import { Button, Form, Progress, Table } from 'antd';
import BatchStudentTable from './batchStudentTable/batchStudentTable';
import { notification} from "antd";
import dayjs from 'dayjs';

import {insertFireBaseChartDetails} from '../../../redux/studentLogin/action';


import {scheduleimage,rcImage} from '../../../images';
import InstructorCancelClass from './cancelClass/instructorCancelClass';
import InstructorRescheduleClass from './rescheduleClass/instructorRescheduleClass';
import moment from 'moment';
import { createRescheduleClass,classCancelNotificationDetails ,createRescheduleClassNotification} from '../../../redux/instructorRescheduleClass/action';
import { type } from '@testing-library/user-event/dist/type';
import { createInstructorCancelClass} from '../../../redux/instructorCancelClass/action';

import {addClassStudnetAttendance} from '../../../redux/studentDashboard/relatedCourses/action.js';

let ViewRescheduleClassSingleBatch=()=> {




    
    let dispatch = useDispatch();
    const params = useParams()
    const [form] = Form.useForm();

    const [api, contextHolder] = notification.useNotification();

    let [meetingLink,setMeetingLink] = useState();
    let [isActive,setIsActive] = useState(false);

    let [batchInformation,setBatchInformation] = useState();

    let [msg,setMsg] = useState();


    let [studentsInfo,setStudentsInfo] = useState();

    let [openRescheduleModal,setOpenRescheduleModal] = useState(false);

    let [cancelClassModal,setCancelClassModal] = useState(false);

    let batchRelatedStudents = useSelector((state)=>state.instructorBatchReducer?.allBatchRelatedStudents?.data);

    let batchDetail = useSelector((state)=>state.instructorDashboardReducer?.instructorCurrentReScheduleBatchesView?.data);

    // let checkClassIsRescheduled = useSelector((state)=>state.instructorRescheduleClassReducer?.instructorSchedulesCreated)
    
    let classRescheduleNotification = useSelector((state)=>state.instructorRescheduleClassReducer?.getClassRescheduleNotification)

    let classCancelNotification = useSelector((state)=>state.instructorRescheduleClassReducer?.getClassCancelNotification)

    let cancelClassCreate = useSelector((state)=>state.instructorCancelClassReducer?.instructorCreateCancelClass)
    
    let rescheduleClassCreate = useSelector((state)=>state.instructorRescheduleClassReducer?.instructorSchedulesCreated)

    useEffect(()=>{

        console.log(rescheduleClassCreate,'cancelClassCreate')

        if(rescheduleClassCreate){
            openNotification(rescheduleClassCreate)

            setMsg(rescheduleClassCreate.res_status)


            if (rescheduleClassCreate.res_status == true) {
                setOpenRescheduleModal(false)
            }
            if (rescheduleClassCreate.res_status == false) {
                setOpenRescheduleModal(true)
            }

            if(rescheduleClassCreate.res_status == true){
                form.setFieldsValue({['reason']:''})
        form.setFieldsValue({['batch_end_time']:''})
        form.setFieldsValue({['request_timing']:''})
        form.setFieldsValue({['request_date']:''})
        form.setFieldsValue({['scheduled_date']:''})
            }else{

            }

        }else{

        }
      
       
    },[rescheduleClassCreate])
    
    useEffect(()=>{

        console.log(cancelClassCreate,'cancelClassCreate')

        if(cancelClassCreate){
            openNotification(cancelClassCreate)
            setMsg(cancelClassCreate.res_status)

            if(cancelClassCreate.res_status == true){
                form.setFieldsValue({['reason']:''})
                form.setFieldsValue({['canceled_date']:''})
                form.setFieldsValue({['cancel_batch_start_time']:''})
                form.setFieldsValue({['cancel_batch_end_time']:''})
            }else{

            }

        }else{

        }
      
       
    },[cancelClassCreate])


    const openNotification = (status) => {
       

        if(status.res_status == true){

            if(msg == true){
                api.info({
                    description:
                    `${status.msg}`,
          
                  });

                setMsg(false)
            }else{

            }

        }else{

        }

      
          
      
        
      
      };

    useEffect(()=>{
        if(classRescheduleNotification){

            const fcmToken = classRescheduleNotification?.data&&Object.values(classRescheduleNotification?.data).map(item => item.fcmtoken);
  
            const usernames = classRescheduleNotification?.data&&Object.values(classRescheduleNotification?.data).map(item => item.username).join(',');
        
          let payloadFireBase = {
            "sender_name": window.localStorage.getItem("instructorEmail"),
            "receiver_name":usernames,
            "peer_name": "Reschedule Class Request",
            "peer_chat_type": "Reschedule Class Request",//group chat
            "input": "Reschedule Class Request",
            "firebase_tokens": fcmToken, 
            "msg_type": "insert",
            "peer_type": "insert"
          };
          dispatch(insertFireBaseChartDetails(payloadFireBase));
      
        }else{

        }
      
       
    },[classRescheduleNotification])

    useEffect(()=>{
        if(classCancelNotification){

            const fcmToken = classCancelNotification?.data&&Object.values(classCancelNotification?.data).map(item => item.fcmtoken);
  
            const usernames = classCancelNotification?.data&&Object.values(classCancelNotification?.data).map(item => item.username).join(',');
        
          let payloadFireBase = {
            "sender_name": window.localStorage.getItem("instructorEmail"),
            "receiver_name":usernames,
            "peer_name": "Cancel Class Request",
            "peer_chat_type": "Cancel Class Request",//group chat
            "input": "Cancel Class Request",
            "firebase_tokens": fcmToken, 
            "msg_type": "insert",
            "peer_type": "insert"
          };
          dispatch(insertFireBaseChartDetails(payloadFireBase));
      
        }else{

        }
      
       
    },[classCancelNotification])
   


    let [rescheduleClassDetails,setRescheduleClassDetails] = useState({

        instructor_id:window.localStorage.getItem("instructor_id"),
        course_id:"",
        batch_id:params.id,
        tenant_id:window.localStorage.getItem("tenant_Id"),
        scheduled_date:"",
        request_date:"",
        request_timing:"",
        batch_end_time:"",
        reason:""

    });

    let[cancelClassDetails,setCancelClassDetails] = useState({
        "instructor_id":window.localStorage.getItem("instructor_id"),
        "batch_id":params.id,
        "course_id":"",
        "canceled_date":"",
        "cancel_batch_start_time":"",
        "cancel_batch_end_time":"",
        "tenant_id":1,
        "reason":""
    })

    // "scheduled_date":"13/02/2024",
    const handleChange = (e,type) =>{
        console.log(e,"onchangeValue")
        if (type.name == "scheduled_date") {
            setRescheduleClassDetails({...rescheduleClassDetails,[type.name]:e})
            form.setFieldsValue({[type.name]: e})

        }

        if (type.name == "request_date") {
            setRescheduleClassDetails({...rescheduleClassDetails,[type.name]:e})
            form.setFieldsValue({[type.name]: e})

        }

        if (type.name == "request_timing") {
            console.log(e.$d,"startTime")
            const date = new Date(e?.$d);

            // Extract hours and minutes
            const hours = date.getHours();
            const minutes = date.getMinutes();

            // Convert hours to AM/PM format
            const ampm = hours >= 12 ? 'PM' : 'AM';
            const formattedHours = hours % 12 || 12; // Handle midnight (0 hours) as 12 AM

            // Format the time as "hh:mm AM/PM"
            const formattedTime = `${formattedHours}:${minutes < 10 ? '0' : ''}${minutes} ${ampm}`;
            console.log(formattedTime,"start")
             setRescheduleClassDetails({...rescheduleClassDetails,[type.name]:(formattedTime)})
        }

        if (type.name == "batch_end_time") {
            const date = new Date(e?.$d);

            // Extract hours and minutes
            const hours = date.getHours();
            const minutes = date.getMinutes();

            // Convert hours to AM/PM format
            const ampm = hours >= 12 ? 'PM' : 'AM';
            const formattedHours = hours % 12 || 12; // Handle midnight (0 hours) as 12 AM

            // Format the time as "hh:mm AM/PM"
            const formattedEndTime = `${formattedHours}:${minutes < 10 ? '0' : ''}${minutes} ${ampm}`;
            console.log(formattedEndTime,"end")
             setRescheduleClassDetails({...rescheduleClassDetails,[type.name]:formattedEndTime})
        }

        if (type.name == "reason") {
            setRescheduleClassDetails({...rescheduleClassDetails,[type.name]:e.target.value})
        }
        console.log(rescheduleClassDetails,"formValues")
    }

    const handleClassChange = (e,type) =>{
        if (type.name == "cancel_batch_start_time") {
            const date = new Date(e?.$d);

            // Extract hours and minutes
            const hours = date.getHours();
            const minutes = date.getMinutes();

            // Convert hours to AM/PM format
            const ampm = hours >= 12 ? 'PM' : 'AM';
            const formattedHours = hours % 12 || 12; // Handle midnight (0 hours) as 12 AM

            // Format the time as "hh:mm AM/PM"
            const formattedStartTime = `${formattedHours}:${minutes < 10 ? '0' : ''}${minutes} ${ampm}`;
            console.log(formattedStartTime,"end")
            setCancelClassDetails({...cancelClassDetails,[type.name]:formattedStartTime})
        }

        if (type.name == "cancel_batch_end_time") {
            const date = new Date(e?.$d);

            // Extract hours and minutes
            const hours = date.getHours();
            const minutes = date.getMinutes();

            // Convert hours to AM/PM format
            const ampm = hours >= 12 ? 'PM' : 'AM';
            const formattedHours = hours % 12 || 12; // Handle midnight (0 hours) as 12 AM

            // Format the time as "hh:mm AM/PM"
            const formattedEndTime = `${formattedHours}:${minutes < 10 ? '0' : ''}${minutes} ${ampm}`;
            console.log(formattedEndTime,"end")
            setCancelClassDetails({...cancelClassDetails,[type.name]:formattedEndTime})
        }

        if (type.name == "reason") {
            setCancelClassDetails({...cancelClassDetails,[type.name]:e.target?.value})
        }

        if (type.name == "canceled_date") {
            setCancelClassDetails({...cancelClassDetails,[type.name]:e})
            form.setFieldsValue({[type.name]: e})

        }

        console.log(cancelClassDetails,"onchage formvals")
    }


    const onFinish = (values) =>{
     


        console.log(moment(rescheduleClassDetails.scheduled_date).format('DD/MM/YYYY'))

        console.log( moment(rescheduleClassDetails.request_date).format('DD/MM/YYYY'))

        const dateObjectscheduled_date = new Date(rescheduleClassDetails.scheduled_date);
        const dateObjectrequest_date = new Date(rescheduleClassDetails.request_date);

       

        
        let payload ={
            "operation_type":"insert",
            "op_field_details":{
                  "course_id": batchDetail[0].course_id,
                  "batch_id":rescheduleClassDetails?.batch_id,
                  "instructor_id":rescheduleClassDetails?.instructor_id,
                  "tenant_id":window.localStorage.getItem("tenant_Id"),
                  "scheduled_date":moment(dateObjectscheduled_date).format('DD/MM/YYYY'),
                  "request_date": moment(dateObjectrequest_date).format('DD/MM/YYYY'),
                  "request_timing": rescheduleClassDetails?.request_timing,
                  "batch_end_time": rescheduleClassDetails?.batch_end_time,
                  "reason": rescheduleClassDetails?.reason
            }
         }
         console.log(payload,"resche")
          dispatch(createRescheduleClass(payload))




          let payload1 ={
            "notification_type":"reschedule_class_request_notification",
            "project_name":"lms",
            "batch_id":rescheduleClassDetails?.batch_id,
            "tenant_id":window.localStorage.getItem("tenant_Id"),
            "request_date": moment(dateObjectrequest_date).format('DD/MM/YYYY'),

         }
          dispatch(createRescheduleClassNotification(payload1))


          setOpenRescheduleModal(false);
          setCancelClassModal(false)
    }

    let onFinish1 = (values) =>{
        console.log(values)


        const dateObjectscheduled_date = new Date(cancelClassDetails?.canceled_date);


        let cancelClassPayload ={
            "operation_type": "insert",
            "op_field_details": {
                "instructor_id":window.localStorage.getItem("instructor_id"),
                "course_id": batchDetail[0].course_id,
                 "batch_id":cancelClassDetails?.batch_id,
                "canceled_date":moment(dateObjectscheduled_date).format('DD/MM/YYYY'),
                "cancel_batch_start_time": cancelClassDetails?.cancel_batch_start_time,
                "cancel_batch_end_time": cancelClassDetails?.cancel_batch_end_time,
                "tenant_id":window.localStorage.getItem("tenant_Id"),
                "reason": cancelClassDetails?.reason
            }
        }
        dispatch(createInstructorCancelClass(cancelClassPayload))


        let payload1 ={
            "notification_type":"cancel_class_request_notification",
            "project_name":"lms",
            "batch_id":cancelClassDetails?.batch_id,
            "tenant_id":window.localStorage.getItem("tenant_Id"),
            "cancelled_date":moment(dateObjectscheduled_date).format('DD/MM/YYYY')

         }
        //  console.log(payload,"resche")
          dispatch(classCancelNotificationDetails(payload1))

        setOpenRescheduleModal(false);
        setCancelClassModal(false)
    }

    let navigate = useNavigate()
    let gotoDashboard = ()=>{
        navigate("/instructorDashboard")
    }
    useEffect(()=>{
        
        let payload = {
            "operation_type": "view_reschedule_batch_detail",
            "batch_id": params.id
        }
        dispatch(getInstructorReScheduleViewClassDetailDataView(payload))
        setBatchInformation("")
       
    },[])

    useEffect(()=>{
        if (batchRelatedStudents) {
            setStudentsInfo(batchRelatedStudents)
            console.log(batchRelatedStudents,"batchRelatedStudents")
        }
    },[batchRelatedStudents])


    useEffect(()=>{
        if (batchDetail) {
            setBatchInformation(batchDetail[0]);
            console.log(batchDetail[0],"viewSingle")
        }
    },[batchDetail])


    

    let handleReschedule = () =>{

        setRescheduleClassDetails({

            instructor_id:window.localStorage.getItem("instructor_id"),
            course_id:"",
            batch_id:params.id,
            tenant_id:window.localStorage.getItem("tenant_Id"),
            scheduled_date:"",
            request_date:"",
            request_timing:"",
            batch_end_time:"",
            reason:""
    
        });

        console.log(rescheduleClassDetails,'rescheduleClassDetails')

        // setFormValues({...formValues,["reason"]:""})
    //  setFormValues({...formValues,["course_category_id"]:"select"});
    //  setFormValues({...formValues,["course_id"]:"select"})
    //  setFormValues({...formValues,["instructor_id"]:"select"})
    //  setFormValues({...formValues,["meeting_link"]:""})
    //  setFormValues({...formValues,["week_days"]:""})

    // instructor_id:window.localStorage.getItem("instructor_id"),
    // course_id:"",
    // batch_id:params.id,
    // tenant_id:window.localStorage.getItem("tenant_Id"),
    // scheduled_date:"",
    // request_date:"",
    // request_timing:"",
    // batch_end_time:"",
    // reason:""

        setOpenRescheduleModal(true)
    }

    let cancelClass = () =>{


        setCancelClassDetails({
            "instructor_id":window.localStorage.getItem("instructor_id"),
            "batch_id":params.id,
            "course_id":"",
            "canceled_date":"",
            "cancel_batch_start_time":"",
            "cancel_batch_end_time":"",
            "tenant_id":1,
            "reason":""
        })


        setCancelClassModal(true)
    }

    let handleCloseModal = () =>{
        setOpenRescheduleModal(false);
        setCancelClassModal(false)

    
        form.setFieldsValue({['reason']:''})
        form.setFieldsValue({['batch_end_time']:''})
        form.setFieldsValue({['request_timing']:''})
        form.setFieldsValue({['request_date']:''})
        form.setFieldsValue({['scheduled_date']:''})


        form.setFieldsValue({['reason']:''})
        form.setFieldsValue({['canceled_date']:''})
        form.setFieldsValue({['cancel_batch_start_time']:''})
        form.setFieldsValue({['cancel_batch_end_time']:''})


        setCancelClassDetails({
            "instructor_id":window.localStorage.getItem("instructor_id"),
            "batch_id":params.id,
            "course_id":"",
            "canceled_date":"",
            "cancel_batch_start_time":"",
            "cancel_batch_end_time":"",
            "tenant_id":1,
            "reason":""
        })
    }


    let addStudentAttendance = useSelector((state)=>state.relatedCoursesReducer?.addAttendanceStudent);

    useEffect(()=>{
        if(addStudentAttendance){
            //res_status
            console.log(addStudentAttendance?.msg,'addStudentAttendance')
  
            openNotification1(addStudentAttendance?.msg,addStudentAttendance?.res_status)
        }
      },[addStudentAttendance])


      const openNotification1=(msg,status)=>{
        if (status == true) {   
  
            console.log(meetingLink,'meetingLink')
            
  
            if(isActive == true){
                window.location.href = meetingLink;
  
            }else{
  
            }
  
            setIsActive(false)
          }
          if (status == false) {
  
            if(isActive == true){
                api.info({
                    description:
                      `${msg}`,
                  });
            }else{
  
            }
           
  
            setIsActive(false)
  
          }
    }

    
  const joinClass = (e,idd) => {

    setMeetingLink(idd.meeting_link)
    setIsActive(true)
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0'); // Adding 1 because getMonth() returns month from 0-11
    const day = String(today.getDate()).padStart(2, '0');
  
    const formattedDate = `${year}-${month}-${day}`;

    console.log(formattedDate,'formattedDate')


    const today1 = new Date();
    let hours = today1.getHours();
    const minutes = String(today1.getMinutes()).padStart(2, '0');
    const ampm = hours >= 12 ? 'PM' : 'AM';
  
    hours = hours % 12;
    hours = hours ? hours : 12; // The hour '0' should be '12'
    const formattedTime = `${String(hours).padStart(2, '0')}:${minutes} ${ampm}`;

    console.log(formattedTime,'formattedTime')


    let payload =
    {
       

        "operation_type": "check_in",
        "op_field_details": {
            "role_type": "instructor",
            "instructor_id": window.localStorage.getItem("instructor_id"),
            "batch_id": idd.batch_id,
            "tenant_id": window.localStorage.getItem("tenant_Id"),
            "date":formattedDate,
            "check_in_time":formattedTime
        }
    }
    dispatch(addClassStudnetAttendance(payload)) 
  };
  return (
    <>
    <div className='viewSingleBatchContainer'>
    {contextHolder}
        <div className='goToDashboard' >
        <div><h3>BATCH DETAILS</h3></div>
            <div className='backToDashboard' onClick={gotoDashboard}><img src={carouselLeft}/>
            <div>Back to dashboard</div></div>
            <div>
            </div>
        </div>

        <div className='singleBatcContainer'>
            <div className='sampleRow'>
            <p><b>Batch name: </b>{batchInformation?.batch_name}</p>
            <p><b>Course name: </b>{batchInformation?.course_name}</p>

            <p>{batchInformation?.course_description}</p>

            <div className='timelineScheduleMeetings2'>
  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '10px' }}>
    <img src={scheduleimage} className='scheduleimage' alt='Schedule'/>
    <b >Scheduled Time:</b> {batchInformation?.schedule_time}-{batchInformation?.reschedule_timing}
  </div>
</div>

            {/* <div className='timelineScheduleMeetings'>
                <div style={{height:'20px'}}>
                <img src={scheduleimage} className='scheduleimage'/>
                <b style={{marginBottom:'10px', height:"20px", }}>Scheduled Time:</b> {batchInformation?.schedule_time}-{batchInformation?.course_time}

                </div>
            </div> */}



            <div className='timelineScheduleMeetings2'>
             <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '10px' }}><img src={rcImage} className='scheduleimage'/><b>Meeting Link: </b><a href={batchInformation?.meeting_link}>{batchInformation?.meeting_link}</a></div>
            </div>
            <div className='classBtns'>
                <Button type="dashed"  danger onClick={cancelClass}>
                Cancel class
                </Button>
                <Button type="dashed"  primary onClick={handleReschedule}>
                Reschedule class
                </Button>
                {/* <Button type="primary" primary>
                <a href={batchInformation?.meeting_link}>Start class</a>
                </Button> */}

<Button type="primary" onClick={(e) => joinClass(e, { batch_id: batchInformation?.batch_id,meeting_link: batchInformation?.meeting_link })}>
        Start class
      </Button>
            </div>
            </div>
            <div className='secondRow'>
                <div className='alignRight '>
            <img src={batchInformation?.course_thumbnail} className='batchCourseImage'/>
            </div>
            </div>
        </div>

        <div className='classTracking'>
            <div className='headerTrackingClass'>
            <p>Class progress</p>
            <p className='alignRight classesLeft'>{batchInformation?.days_completed} Days left out of {batchInformation?.remaining_days} Days</p>
            </div>
            
        <Progress percent={batchInformation?.batch_progress} />
        <div className='daysTracking'>
           <div> <p className='dot'></p></div>
            <div><p>Days completed {batchInformation?.days_completed}</p></div>

            <div> <p className='dotDisable'></p></div>
            <div><p>Days left {batchInformation?.remaining_days}</p></div>
        </div>

        </div>

        <div className='batchTable'>
            <div className='studentTable'>
                <h3>Batch students</h3>
                <BatchStudentTable/>
            </div>

            <div className='commentTable'>
                <h3>Comments</h3>
            </div>
        </div>
    {openRescheduleModal ? 
       <ModalComponent title={"Reschedule Class"}
       content={<InstructorRescheduleClass 
        handleChange = {handleChange}
        form={form} onFinish={onFinish} rescheduleClassDetails={rescheduleClassDetails}/>}
       open={openRescheduleModal}
       handleCancel={handleCloseModal}
     //  footer= {categoryFooter}
     style={{ minHeight: `500px` }}
 
      ></ModalComponent>
      :""}



        {cancelClassModal ? 
         <ModalComponent title={"Cancel Class"}
         content={<InstructorCancelClass 
            form={form} onFinish={onFinish1} 
            handleClassChange={handleClassChange}
            cancelClassDetails = {cancelClassDetails}/>}
         open={cancelClassModal} 
          handleCancel={handleCloseModal}
       //  footer= {categoryFooter}
       style={{ minHeight: `500px` }}
   
        ></ModalComponent>
    :""}
        </div>

        </>
  )
}

export default ViewRescheduleClassSingleBatch