import {INSTRUCTOR_CURRENT_BATCHES, INSTRUCTOR_VIEW_BATCH_DETAIL,INSTRUCTOR_RETRIEVE_CLASS,
    INSTRUCTOR_RESCHEDULE_RETRIEVE_CLASS,INSTRUCTOR_RESCHEDULE_RETRIEVE_CLASS_VIEW
} from './actionTypes';
import apiConfig from '../../apiConfig/api';

import axios from 'axios';

export const getInstructorBatches = (data)=>{
    return {
        type: INSTRUCTOR_CURRENT_BATCHES,
        payload: data
    }
}

export const getInstructorViewBatchDetail = (data)=>{
    console.log(data)
     return {
        type: INSTRUCTOR_VIEW_BATCH_DETAIL,
        payload:data
     }
}

export const getInstructorViewClassDetail = (data)=>{
    console.log(data)
     return {
        type: INSTRUCTOR_RETRIEVE_CLASS,
        payload:data
     }
}

export const retrieveCurrentInstructorBatches = (payload)=>{
    return(dispatch)=>{
        axios.post(apiConfig.baseUrl+"e_lms/v1/instructor_page",payload
        ).then((res)=>{
            if (res.data?.res_status == true) {
                dispatch(getInstructorBatches(res.data));    
            }
            
        }).catch(err=>{
            console.log(err)
        })
}
}

export const retrieveInstructorViewBatchDetail = (payload)=>{
    return(dispatch)=>{
        axios.post(apiConfig.baseUrl+"e_lms/v1/instructor_page",payload
        ).then((res)=>{
            if (res.data?.res_status == true) {
                dispatch(getInstructorViewBatchDetail(res.data));    
            }
            
        }).catch(err=>{
            console.log(err)
        })
}
}

export const retrieveInstructorClassDetails = (payload)=>{
    return(dispatch)=>{
        axios.post(apiConfig.baseUrl+"e_lms/v1/instructor_page",payload
        ).then((res)=>{
            // console.log(res.data,'SACHIN')

            if (res.data?.res_status == true) {

                console.log(res.data,'SACHIN')
                dispatch(getInstructorViewClassDetail(res.data));    
            }
            
        }).catch(err=>{
            console.log(err)
        })
}
}


export const getInstructorReScheduleViewClassDetail = (data)=>{
    console.log(data)
     return {
        type: INSTRUCTOR_RESCHEDULE_RETRIEVE_CLASS,
        payload:data
     }
}

export const getInstructorReScheduleViewClassDetailData = (payload)=>{
    return(dispatch)=>{
        axios.post(apiConfig.baseUrl+"e_lms/v1/instructor_page",payload
        ).then((res)=>{
            if (res.data?.res_status == true) {
                dispatch(getInstructorReScheduleViewClassDetail(res.data));    
            }
            
        }).catch(err=>{
            console.log(err)
        })
}
}


export const getInstructorReScheduleViewClassDetailView = (data)=>{
    console.log(data)
     return {
        type: INSTRUCTOR_RESCHEDULE_RETRIEVE_CLASS_VIEW,
        payload:data
     }
}

export const getInstructorReScheduleViewClassDetailDataView = (payload)=>{
    return(dispatch)=>{
        axios.post(apiConfig.baseUrl+"e_lms/v1/instructor_page",payload
        ).then((res)=>{
            if (res.data?.res_status == true) {
                dispatch(getInstructorReScheduleViewClassDetailView(res.data));    
            }
            
        }).catch(err=>{
            console.log(err)
        })
}
}